.photo-card {
  border-radius: 0.375rem;
  display: block;
  width: 100%;
}
.photo-container {
  font-size: 0;
  padding: 0.75rem;
  transition: padding 0.5s ease-out, margin 0.5s ease-out;
}
.photo-container:hover {
  cursor: pointer;
}

.photo-container:hover, .photo-container--1-1:hover {
  padding: 0.25rem;
}

.photo-container--9-16:hover {
  padding: 0 calc(0.75rem - (0.75rem * (9/16)));
}

.photo-container--3-2:hover {
  padding: 0.25rem calc(0.75rem - (0.5rem * (1.5)));
}

.photo-container--4-3:hover {
  padding: 0.25rem calc(0.75rem - (0.5rem * (4/3)));
}

.photo-container--4-5:hover {
  padding: 0.25rem calc(0.75rem - (0.5rem * (0.8)));
}
.photo-container--1-1, .photo-container--1-1 img {
  aspect-ratio: 1;
}

.photo-container--9-16, .photo-container--9-16 img {
  aspect-ratio: calc(9 + 0.75rem / 16 + 0.75rem);
}

.photo-container--3-2, .photo-container--3-2 img {
  aspect-ratio: 3/2;
}

.photo-container--4-3, .photo-container--4-3 img {
  aspect-ratio: 4/3;
}

.photo-container--4-5, .photo-container--4-5 img {
  aspect-ratio: 4/5;
}