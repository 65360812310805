.configurator-container {
  display: inline-block;
  text-align: start;
  width: Calc(min(90vh, 90vw) + 0.75rem);
}
@media screen and (min-width: 768px) {
  .configurator-container {
    max-width: Calc(min(65vh, 65vw) + 1.25rem);
  }
}

.configurator-header {
    font-size: 1.4rem;
    font-weight: 600;
}

.min-w-200 {
    min-width: 170px;
}

.btn:hover {
    opacity: 60%;
}

.btn-theme-light {
    background-color: white !important;
}

/* Highlight Selected boardsize button */
.size-4-selected :nth-child(2) {
    opacity: 60% !important;
}
.size-9-selected :nth-child(3) {
    opacity: 60% !important;
}
.size-16-selected :nth-child(4) {
    opacity: 60% !important;
}