.routes-popover-pos {
  
  visibility: hidden;
  pointer-events: none;
  
  font-size: clamp(19px, calc(20px + 1vw), 3vw);
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  top: -55px;
}

.autocomplete-menu {
  background-color: black;
  color: white;
  position: relative;
  max-height: min(80vh, 500px);
  overflow-y: auto;
}

.autocomplete-menu span {
  position: relative;
  color: white;
  text-shadow: none;
}

.autocomplete-menu.show {
  opacity: 0%;
  animation: ShowTransition 0.4s ease-out forwards;
}

@keyframes ShowTransition {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}


/***   Scrollbar   ***/

.autocomplete-menu::-webkit-scrollbar {
  width: 0.8em;
}

.autocomplete-menu::-webkit-scrollbar-track {
  background-color: black;
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}
 
.autocomplete-menu::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 1rem;
  width: 0.8em;
}

.autocomplete-menu::-webkit-scrollbar-thumb:vertical:hover {
  background-color: rgb(112, 112, 112);
}