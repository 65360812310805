
.bg-cell-1 {
    background-color: blue;
    color: blue;
}
.bg-cell-2 {
    background-color: red;
    color: red;
}
.bg-cell-3 {
    background-color: green;
    color: green;
}
.bg-cell-4 {
    background-color: yellow;
    color: yellow;
}