.zoomed-out {
  max-width: fit-content;
  height: 100vh;
}

.zooming-img {
  width: calc(100vw - var(--scrollbar-width));
  overflow: hidden;
}

.modal-content {
  width: fit-content;
  height: fit-content;
  margin: auto;
  border: none;
  background-color: transparent !important;
}

.btn-close {
  position: fixed;
  top: 4vw;
  right: 5vw;

  width: 2vw;
  height: 2vw;

  padding: 10px;
  border-radius: 100%;
  background-color: white;
}

.modal-body::-webkit-scrollbar {
  width: 0.9em;
}

.modal-body-content::-webkit-scrollbar-track {
  background-color: #0a0b0d;
}
 
.modal-body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 1rem;
}

.modal-body::-webkit-scrollbar-thumb:vertical:hover {
  background-color: rgb(112, 112, 112);
}

.photography-list {
  padding: 0;
  list-style: none;
  column-gap: 0;
  columns: 1;
}
@media screen and (min-width: 768px) {
  .photography-list {
    columns: 2;
  }
}
@media screen and (min-width: 1200px) {
  .photography-list {
    columns: 3;
  }
}