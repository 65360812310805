.nav-card {
  text-decoration: none;
  transition: box-shadow 0.5s;
}
.nav-card:hover {
  cursor: pointer;
}

.hero-nav-col {
  padding: 0.75rem;
  transition: padding 0.5s, margin 0.5s;
}
.hero-nav-col:hover {
  padding: 0 calc(0.75rem - (0.75rem * (0.83712551)));
}

.card-title {
  font-size: 1rem;
  transition: font-size 0.5s;
  width: 100%;
  position: absolute;
  bottom: 0;
}
.hero-nav-col:hover .card-title {
  font-size: 1.2rem !important;
}
@media screen and (min-width: 576px) {
  .card-title {
    font-size: 1.2rem!important;
  }
  .hero-nav-col:hover .card-title {
    font-size: 1.4rem !important;
  }
}
@media screen and (min-width: 768px) {
  .card-title {
    font-size: 1.5rem!important;
  }
  .hero-nav-col:hover .card-title {
    font-size: 1.7rem !important;
  }
}
@media screen and (min-width: 1400px) {
  .card-title {
    font-size: 1.7rem!important;
  }
  .hero-nav-col:hover .card-title {
    font-size: 1.9rem !important;
  }
}

.hero-nav-img {
  position: relative;
  bottom: 1.5rem;
}
