.cursor {
  text-shadow: none;
  mix-blend-mode:difference;
  opacity: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .cursor {
    opacity: 0%;
    animation: CursorBlink infinite 2s linear;
    position: relative;
  }
}

@keyframes CursorBlink {
  0% {opacity: 100%;}
  25% {opacity: 100%;}
  50% {opacity: 0%;}
  75% {opacity: 100%;}
  100% {opacity: 100%;}
}