.ascii-container {
  position: absolute;
  top: 0;
  overflow: hidden;
  width: 99vw;
  height: 130vh;
  pointer-events: none;
}

/*** LOADING ANIMATION ***/

.ascii-loading {
  position: absolute;
  object-fit: contain;
  text-align: center;
  top: -10vh;
  left: -20vw;
  font-size:5px;
  height: 130vh;
  opacity: 0%;
  
  border: none; 
  pointer-events: none;

  transition: right 1s ease-out 0s;
  animation: LoadAsciiReducedMotion 2s ease-out 1s forwards;
}

/* Load animation */
@media (prefers-reduced-motion: no-preference) {
  .ascii-loading {
    animation: slide-in 2s ease-out 1s forwards;
  }
}

@keyframes LoadAsciiReducedMotion {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}

@keyframes slide-in {
  0% {
    opacity: 0%;
    transform: translateX(25vw);
  }
  100% {
    opacity: 100%;
    transform: translateX(0);
  }
}

@media screen and (min-width: 576px) {
  @keyframes slide-in {
    0% {
      opacity: 0%;
      transform: translateX(25vw);
    }
    100% {
      opacity: 100%;
      transform: translateX(0);
    }
  }
}

@media screen and (min-width: 768px) {
  @keyframes slide-in {
    0% {
      opacity: 0%;
      transform: translateX(20vw);
    }
    100% {
      opacity: 100%;
      transform: translateX(0);
    }
  }
}

@media screen and (min-width: 992px) {
  @keyframes slide-in {
    0% {
      opacity: 0%;
      transform: translateX(15vw);
    }
    100% {
      opacity: 100%;
      transform: translateX(0);
    }
  }
}

@media screen and (min-width: 1400px) {
  @keyframes slide-in {
    0% {
      opacity: 0%;
      transform: translateX(10vw);
    }
    100% {
      opacity: 100%;
      transform: translateX(0);
    }
  }
}

/* Small screens (default) */
.ascii {
  position: absolute;
  object-fit: contain;
  text-align: center;
  top: -10vh;
  left: -20vw;
  font-size:5px;
  height: 130vh;
  
  border: none; 
  pointer-events: none;

  transition: left 1s ease-out, left 1s ease-out;
}

/* Positioning */
@media screen and (min-width: 576px) {
  .ascii, .ascii-loading {
    left: 1vw;
  }
}
@media screen and (min-width: 768px) {
  .ascii, .ascii-loading {
    left: 10vw;
  }
}
@media screen and (min-width: 992px) {
  .ascii, .ascii-loading {
    left: 20vw;
  }
}
@media screen and (min-width: 1200px) {
  .ascii, .ascii-loading {
    left: 25vw;
  }
}
@media screen and (min-width: 1400px) {
  .ascii, .ascii-loading {
    left: 35vw;
  }
}