.maze-container {
  display: inline-block;
  width: 100%;
  max-width: min(95vh, 95vw);
}

@media screen and (min-width: 768px) {
  .maze-container {
    max-width: min(65vh, 65vw);
  }
}