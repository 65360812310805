/* Terminal */
.terminal {
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding: 0.5rem;
  cursor: text;

  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.command-prefix {
  white-space: pre-wrap;
}

/* Input */
.terminal, .terminal-input, .terminal-text-overlay {
  font-family: 'Roboto Mono';
  font-size: calc(10px + 1vw);
  text-shadow: 0px 0px 50px black, 0px 0px 20px black, 0px 0px 10px black ;
}

.terminal-input {
  position: absolute;
  background-color: transparent;
  border: none;
  outline: none;
  text-shadow: none;
  caret-color: transparent;

  width: 0; /* Width is dynamically updated to match text content width */
  max-width: max-content;
  white-space: initial;

  color : transparent; /* Hide input text (text is displayed in overlay to hide text selection) */
}

.terminal-input::selection {
  color: transparent;
}
.terminal-input::-moz-selection {
  color: transparent;
}

/* Input text overlay */
/* Input has the text hidden, ".input-text-overlay" contains text matching input */

.terminal-text-overlay {
  display: contents;
  position: absolute;
  padding: 1px 0;
  background-color: transparent;
  width: 0;
  pointer-events: none;
  white-space: break-spaces;
  overflow-wrap: break-word;
}


.command-line {
  position: fixed;
  top: calc(100% - 2.3rem);
  width: 90%;
  padding-left: clamp(0px, 4.5rem, calc(5vw - 0.5rem));
  line-height: 2.2rem;
}


.terminal-arrow {
  font-size: calc(10px + 1vw);
  text-indent: clamp(0px, calc(5rem - 2ch), calc(5vw - 2ch));
  position: absolute;
}

/*** ////////    THEMES    //////// ***/

.terminal-dark, .terminal-dark .terminal-text-overlay{
  color: white;
}

.terminal-light {
  color: black;
}