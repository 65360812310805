.about-me__row {
  position: relative;
  min-height: 100vh;
}

/* Hero styles */
.hero-container {
  display: flex;
  height: 80%;
  align-items: center;
}
.hero-terminal {
  font-weight: 400;
}

.about-me__header {
  position: relative;
  z-index: 0;
}

/* About me bio */
.about-me__bio {
  display: flex;
  padding-top: 2rem;
  padding-bottom: 1rem;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: 1.2rem;
  line-height: 1.8;
}
.about-me__bio div.show {
  animation: LoadReducedMotion ease-out 1s;
}
@media (prefers-reduced-motion: no-preference) {
  .about-me__bio div.show {
    animation: SlideEaseOut ease-out 1s;
  }
}

.about-me__details {
  display: flex;
  margin: auto;
  padding-top: 0rem;
  padding-bottom: 3rem;
  flex-direction: column;
  justify-content: center;
  width: clamp(40vw, 50rem, 100%);
  height: 100%;
  font-size: 1.2rem;
  line-height: 1.8;
}

.text-size-placeholder {
  position: absolute;
  color: transparent;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: clamp(40vw, 50rem, 90vw);

  font-size: 1.2rem;
  line-height: 1.8;

  pointer-events: none;
  z-index: -1;
}

/* Job subtitle container */
.about-me__title {
  position: absolute;
  top: 44vh;
}
@media screen and (min-width: 768px) {
  .about-me__title {
    position: absolute;
    top: 45vh;
  }
}

@media screen and (min-width: 1200px) {
  .about-me__title {
    position: absolute;
    top: 40vh;
  }
}

.about-me__title h2 {
  font-weight: 500;
  font-size: clamp(28px, calc(35px + 1vw), 4vw);
}

.about-me__title h3 {
  font-weight: 200;
  font-size: clamp(23px, calc(25px + 1vw), 3vw);
}

.about-me__link {
  padding-left: 0.1rem;

  color: white;
  fill: white;
  font-size: clamp(15px, 1.5rem, 2vw);
  font-weight: 200;
  text-decoration: none;

  opacity: 0%;
  animation: LoadReducedMotion 1.5s ease-out 2.9s forwards;

  --hoverTransitionTime: 0.05s;
}
.about-me__link div {
  vertical-align: middle;
  transition: color var(--hoverTransitionTime) ease-in, font-weight var(--hoverTransitionTime) ease-in;
}
.about-me__link svg {
  vertical-align: sub;
  transition: fill var(--hoverTransitionTime) ease-in;
}
.about-me__link:hover {
  font-weight: 400;
  transition: color var(--hoverTransitionTime) ease-in, font-weight var(--hoverTransitionTime) ease-in, fill var(--hoverTransitionTime) ease-in;
  color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1));
  fill: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1));
}

@media (prefers-reduced-motion: no-preference) {
  .about-me__title h3, .about-me__link {
    transition: font-size 0.4s ease-in;
  }
  .about-me__title {
    transition: padding-left 0.4s ease-in, top 0.4s ease-in;
  }
}

/* Animations */
@keyframes LoadReducedMotion {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}

@keyframes SlideEaseOut {
  0% {
    opacity: 0%;
    position: relative;
    bottom: 40px;
  }
  100% {
    opacity: 100%;
    position: relative;
    bottom: 0;
  }
}



/* Transitions */
