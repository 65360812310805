.footer {
  background-color: black;
  padding: 2rem 0 5rem 0;
}

.footer h4 {
  padding: 0;
  margin: 1rem 0 2rem 0;
}

.footer h5 {
  padding: 0;
  margin: 1rem 0;
}

.footer__link {
  font-weight: 200;
  text-align: start;
  white-space: nowrap;
  padding: 0;
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.footer__link:hover {
  color: white;
}

.footer__link:hover {
  font-weight: 400;
  color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1));
}

@media (min-width: 576px) {
  h5 {
    text-indent: 35%;
  }
  .footer__link, .footer__item {
    margin-left: 35%;
  }
}

.footer__item {
  font-weight: 200;
  text-align: start;
  padding: 0;
  color: white;
  text-decoration: none;
  cursor: pointer;
}
