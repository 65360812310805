/*** ////////    PAGE    //////// ***/

:root {
  --scrollbar-width: 0.9em;
}

/*** ////////    PAGE    //////// ***/

.no-overflow-x, body {
  overflow-x: hidden;
}

.padding-margins {
  padding-left: clamp(0px, 5rem, 5vw);
  padding-right: clamp(0px, 5rem, 5vw);
}
.padding-margins-n2 {
  padding-left: 4vw;
  padding-right: 4vw;
}

@media screen and (min-width: 768px) {
  .padding-margins {
    padding-left: clamp(0px, 10rem, 10vw);
    padding-right: clamp(0px, 10rem, 10vw);
  }
  .padding-margins-n2 {
    padding-left: clamp(0px, 9.25rem, 9vw);
    padding-right: clamp(0px, 9.25rem, 9vw);
  }
}


/*** ////////    HEADER    //////// ***/

.app-header {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.route-header { 
  margin: 0;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.bottom-nav {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  position: fixed;
  bottom: 0;
}


/*** ////////    MAIN CONTENT    //////// ***/
#main-content {
  min-height: 100vh;
}


/*** ////////    SCROLLBAR    //////// ***/
html::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

html::-webkit-scrollbar-track {
  background-color: #0a0b0d;
}
 
html::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 1rem;
}

html::-webkit-scrollbar-thumb:vertical:hover {
  background-color: rgb(112, 112, 112);
}

/*** ////////    THEMES    //////// ***/

.app-dark {
  color: white;
}
.app-light {
  color: black;
}

.logo-dark {
  fill: white;
}
.logo-light {
  fill: black;
}

.app-header-dark {
  background-color: #00000063;
}


/*** ////////    GLOBAL STYLES    //////// ***/

.hide {
  display: none;
}

.hidden {
  visibility: hidden;
}

.w-0 {
  width: 0;
}

.pb-10 {
  padding-bottom: 6rem!important;
}

.row {
  max-width: 100vw;
  margin: 0;
}

@media screen and (min-width: 1400px) {
  .col-xxl-2-4 {
    width: 20%;
  }
}


p, li {
  font-size: 1.25rem;
}

.fs-0 {
  font-size: 0;
}

.fs-md-4 {
  font-size: 1rem;
}
@media screen and (min-width: 576px) {
  .fs-md-4 {
    font-size: 1.2rem!important;
  }
}
@media screen and (min-width: 768px) {
  .fs-md-4 {
    font-size: 1.5rem!important;
  }
}

@media screen and (min-width: 1400px) {
  .fs-md-4 {
    font-size: 1.7rem!important;
  }
}

.hero-row {
  height: 100vh;

  background: -webkit-linear-gradient(black 10%, rgba(0,0,0,0.3) 100%);
  background: -moz-linear-gradient(black 10%, rgba(0,0,0,0.3) 100%);
  background: -ms-linear-gradient(black 10%, rgba(0,0,0,0.3) 100%);
  background: -o-linear-gradient(black 10%, rgba(0,0,0,0.3) 100%);
  background: linear-gradient(black 10%, rgba(0,0,0,0.3) 100%);
}

.bg-gradient-down {
  background: -webkit-linear-gradient(black 0%, rgba(0,0,0,0.3) 100%);
  background: -moz-linear-gradient(black 0%, rgba(0,0,0,0.3) 100%);
  background: -ms-linear-gradient(black 0%, rgba(0,0,0,0.3) 100%);
  background: -o-linear-gradient(black 0%, rgba(0,0,0,0.3) 100%);
  background: linear-gradient(black 0%, rgba(0,0,0,0.3) 100%);
}

.bg-gradient-up {
  background: -webkit-linear-gradient(0deg,black 10%, rgba(0,0,0,0.3) 100%);
  background: -moz-linear-gradient(0deg,black 10%, rgba(0,0,0,0.3) 100%);
  background: -ms-linear-gradient(0deg,black 10%, rgba(0,0,0,0.3) 100%);
  background: -o-linear-gradient(0deg,black 10%, rgba(0,0,0,0.3) 100%);
  background: linear-gradient(0deg,black 10%, rgba(0,0,0,0.3) 100%);
}

.bg-gradient-match {
  background: rgba(0,0,0,0.3);
}

.rot90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.ar-3-2, .ar-3-2 img {
  aspect-ratio: 3/2;
}
.ar-16-33, .ar-16-33 img {
  aspect-ratio: 16/33;
}
.ar-640-321, .ar-640-321 img {
  aspect-ratio: 640/321;
}
.ar-3103-1907, .ar-3103-1907 img {
  aspect-ratio: 3103/1907;
}
.ar-271-210, .ar-271-210 img {
  aspect-ratio: 271/210;
}
.ar-74-43, .ar-74-43 img {
  aspect-ratio: 74/43;
}
.ar-542-305, .ar-542-305 img {
  aspect-ratio: 542/305;
}
.ar-4-3, .ar-4-3 img {
  aspect-ratio: 4/3;
}


/*** ////////    CURSOR HOVER EFFECTS    //////// ***/

.cursor-zoom-in {
  cursor: zoom-in;
}

.cursor-zoom-out {
  cursor: zoom-out;
}


/*** ////////    BOOTSTRAP OVERIDES    //////// ***/

.ratio-1x1 {
  aspect-ratio: 1 !important;
}